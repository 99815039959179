import { defineStore } from 'pinia'
import { getDocs, query, collection, where, updateDoc, doc, serverTimestamp, getDoc, setDoc } from 'firebase/firestore'
import pinia from '@/store'
import { db } from '@/plugins/firebase'
import { useCouponStore } from '@/store/couponStore'

const couponStore = useCouponStore(pinia)

export const useUserStore = defineStore('userStore', {
  state: () => ({
    dbData: null,
    cart: {
      paymentItems: {},
    },
  }),
  getters: {
    user: state => state.dbData,
  },
  actions: {
    // Create
    async createAddress({ userId, data }) {
      const addressRef = doc(collection(db, 'users', userId, 'addresses'))
      await setDoc(addressRef, { ...data, id: addressRef.id })
    },
    async createPaymentMethod({ userId, data }) {
      const paymentMethodRef = doc(collection(db, 'users', userId, 'paymentMethods'))
      await setDoc(paymentMethodRef, { ...data, id: paymentMethodRef.id })
      return paymentMethodRef.id
    },
    async createUserCart({ userId, paymentItems }) {
      const cart = {
        couponId: couponStore?.coupon?.id ?? null,
        paymentItems: {},
      }
      paymentItems.forEach((item, i) => {
        const key = `back_${Date.now() + i}`
        cart.paymentItems[key] = item
      })
      await updateDoc(doc(db, 'users', userId), {
        cart,
      })
    },
    async read(id) { return (await getDoc(doc(db, 'users', id))).data() },
    // Read
    async readByEmail(email) {
      if (!email) return null
      const user = []
      const q = query(collection(db, 'users'), where('email', '==', email))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(doc => { user.push(doc.data()) })
      return user[0] ?? null
    },
    async readMembershipInfo({ id, role, entityType, entityId }) {
      return (await getDoc(doc(db, 'users', id, 'membership', `${role}-${entityType}-${entityId}`))).data()
    },
    // Update
    async update({ id, data }) {
      await updateDoc(doc(db, 'users', id), {
        ...data,
        updatedAt: serverTimestamp(),
      })
    },
  },
})
