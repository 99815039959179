import http from 'axios'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCk8wSWqPgPUOEGsjA9ze6brgojF4FvK0I',
  authDomain: 'owqlo-master-production-id.firebaseapp.com',
  databaseURL: 'https://owqlo-master-production-id.firebaseio.com',
  projectId: 'owqlo-master-production-id',
  storageBucket: 'owqlo-master-production-id.appspot.com',
  messagingSenderId: '737501983455',
  appId: '1:737501983455:web:358966b523fb8405252f6a',
  measurementId: 'G-8M8F6GCE4G',
}

const callBackend = async (endpoint, data) => {
  const backendUrl = `https://${window.isDev ? 'staging-dot-owqlo-master-production-id' : 'owqlo-master-production-id'}.ew.r.appspot.com`
  // const backendUrl = 'http://localhost:8081'
  try {
    const res = await http.post(`${backendUrl}/${endpoint}`, data)
    return res.data
  } catch (error) {
    return error.response.data
  }
}

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const analytics = getAnalytics(firebaseApp)

export { firebaseConfig, firebaseApp, db, analytics, callBackend }
