import { doc, getDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { db } from '@/plugins/firebase'

export const useDynamicFormStore = defineStore('dynamicFormStore', {
  state: () => ({
    dbDataUser: null,
  }),
  getters: {
    dataUser: state => ({ ...state.dbDataUser }),
  },
  actions: {
    async bindDataUser({ organizationId, projectId }) {
      const docRef = doc(db, 'properties', organizationId, 'projects', projectId, 'forms', 'enrollment')
      const docSnap = await getDoc(docRef)
      this.dbDataUser = docSnap.data()
    },
    getFormValues({ form, typeUser = null }) {
      return this.dataUser.fields ? this.dataUser.fields.filter(f => typeUser === 'parent' ? f.requestedOnceForAllChildren : !f.requestedOnceForAllChildren)
        .map(formValue => {
          const field = Object.entries(form).find(([key]) => key === formValue?.id)
          return formValue?.label && ({
            id: formValue?.id,
            value: field ? field[1] : null,
          })
        })?.filter(e => e) : []
    },
  },
})
