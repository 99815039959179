import { Timestamp, doc, getDoc, setDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import pinia from '@/store'
import { callBackend, db } from '@/plugins/firebase'
import { useUserStore } from '@/store/userStore.js'
import { useVendorStore } from '@/store/vendorStore.js'
import { useDynamicFormStore } from '@/store/dynamicFormStore'
import currenciesSymbols from '@/shared/enums/currenciesSymbols.js'

const vendorStore = useVendorStore(pinia)

export const useProjectStore = defineStore('projectStore', {
  state: () => ({
    project: null,
    form: {},
    paymentMethod: null,
  }),
  getters: {
    price: state => state.project?.pricing?.priceOptions[0]?.instant?.amount ?? null,
    priceOptionId: state => state.project?.pricing?.priceOptions[0]?.id,
    timezone: state => state.project?.timezone,
    formatPrice: state => price => state.price ? `${price.toFixed(2)} ${currenciesSymbols[vendorStore.currency]}` : null,
    isProjectFree: state => !state.project?.features?.price,
  },
  actions: {
    setForm({ form }) { this.form = form },
    setPaymentMethod({ paymentMethod }) { this.paymentMethod = paymentMethod },
    async bindProject({ organizationId, projectId }) {
      const docRef = doc(db, 'properties', organizationId, 'projects', projectId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.project = docSnap.data()
      } else {
        console.log('projectStore.js -> bindProject: No such document!')
      }
    },
    async createEnrollment({ organizationId, projectId, data }) {
      const enrollmentsRef = doc(db, 'properties', organizationId, 'projects', projectId, 'enrollments', data.id)
      await setDoc(enrollmentsRef, { ...data })
    },
    async createOrder({ formData, organizationId, projectId }) {
      const userStore = useUserStore()
      const dynamicFormStore = useDynamicFormStore()
      let parentUser = null
      let parentInfoForm = null

      // 0. Verify that the parent's information is requested on the form and parse dynamic form if exists
      if (dynamicFormStore?.dataUser?.parentProfileFields) {
        const { parentDynamicForm, email, ...parentData } = formData.parentForm
        parentInfoForm = parentDynamicForm?.length ? this.parseDynamicForm({ dynamicForm: parentDynamicForm }) : null

        // 1. Back Create parent user if no exixts. If exists update parent user.
        parentUser = await userStore.readByEmail(email)

        if (!parentUser) {
          parentUser = { id: null, ...parentData }
          const response = await callBackend('users/create-programatically', {
            data: parentUser,
            email,
            phone: parentData?.phone,
          })
          parentUser.id = response.id
        } else {
          await userStore.update({ id: parentUser.id, data: parentData })
        }
      }

      // 2. Save parent address, now saved address in users/create-programatically
      // await userStore.createAddress({ userId: parentUser.id, data: formData.addressForm })

      // 3. Back Create child user.
      const paymentItems = await Promise.all(formData.childForm.map(async child => {
        const { childDynamicForm, email, cart, ...childData } = child
        const childUser = { id: null, ...childData }
        const response = await callBackend('users/create-programatically', {
          data: childUser,
          ...(email && { email }),
        })
        if (response.error) { throw new Error(response.error) }
        childUser.id = response.id

        // 4. Save child address
        if (formData.addressForm) await userStore.createAddress({ userId: childUser.id, data: formData.addressForm })

        // 5. Link users if the parent's data is requested
        if (dynamicFormStore?.dataUser?.parentProfileFields) {
          await callBackend('users/link', {
            user1: { id: parentUser?.id, relationshipRole: 'parent' },
            user2: { id: childUser.id, relationshipRole: 'child' },
          })
        }

        // 6. Create enrollment
        const childInfoForm = childDynamicForm?.length ? this.parseDynamicForm({ dynamicForm: childDynamicForm }) : null
        const enrollment = {
          id: childUser.id,
          userId: childUser.id,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          status: 'pendingApproval',
          role: 'player',
          enrolledBy: parentUser?.id ?? childUser?.id,
          ...((parentInfoForm || childInfoForm)
          && {
            info: {
              form: {
                ...(parentInfoForm && { ...parentInfoForm }),
                ...(childInfoForm && { ...childInfoForm }),
              },
            },
          }),
        }
        await this.createEnrollment({ organizationId, projectId, data: enrollment })
        if (this.isProjectFree) { await callBackend('projects/users/approve-enrollment', { organizationId, projectId, enrollmentId: enrollment.id }) }

        // 7. Create payment items
        const cartItems = cart.map(item => ({
          id: item.id,
          type: item.type,
          name: item.name,
          image: item.image,
          amount: item.amount,
          currency: vendorStore.currency,
          units: 1,
          organizationId,
          projectId,
          recipientUserId: childUser.id,
          enrollmentId: enrollment.id,
          ...((item.type === 'project' && this.priceOptionId) && { priceOptionId: this.priceOptionId }),
        }))
        return cartItems
      }))
      if (this.isProjectFree) return true
      const paymentUserId = parentUser?.id ?? formData.childForm[0]?.id
      // 8. Create default payment method
      const defaultPaymentMethodId = await userStore.createPaymentMethod({ userId: paymentUserId, data: this.paymentMethod })
      await userStore.update({ id: paymentUserId, data: { defaultPaymentMethodId } })
      // 9. Create user cart
      const allItems = paymentItems.flatMap(items => items)
      await userStore.createUserCart({ userId: paymentUserId, paymentItems: allItems })
      // 10. Create order
      const order = await callBackend('stripe/create-order', {
        userId: paymentUserId,
      })
      return order
    },
    parseDynamicForm({ dynamicForm }) {
      return dynamicForm.reduce((result, item) => {
        result[item.id] = item.value
        return result
      }, {})
    },
  },
})
